
.save-button-container {
    padding-right: 1em
}

.button-container {
    margin-top: 0.5em;
    margin-bottom: 0.5em
}

.stream-list-container {
    max-height: 100vh
}
