.app {
  margin-top: 1em
}

.sync-container {
  margin-bottom: 1em
}

/* global styles */

/* indicate things are clickable or diabled using hover color changes */

.click-enabled {
  color: #0d6efd;
  cursor: pointer
}

.click-enabled:hover {
  color: #0b5ed7
}

.click-disabled {
  color: gray
}

.click-warning-enabled {
  color: #ffc107;
  cursor: pointer
}

.click-warning-enabled:hover {
  color: #ffca2c
}

.instructions {
  margin-left: 2rem;
  font-size: 1.25rem;
}

.instructions li {
  line-height: 1.75rem;
  padding-bottom: 0.5rem;
}

.stream-status {
  background-color: lightgray;
  padding: 1em;
}
